<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 0 120px;">
      <div class="main">
         <div class="main-tit-flex">
            <img class="logo" src="../../assets/logo.png" alt="">
            <div class="main-tit">{{ $t('down.tit') }}<span style="color:#FFC300;">{{ $t('down.tit2') }}</span> {{ $t('down.tit3') }}</div>
         </div>
         <div style="display: flex;align-items: center;">
            <div>
                <img class="main-img" v-if="lang==='zh-CN'" src="../../assets/market/k.png" alt="">
                <img class="main-img" v-else src="../../assets/market/k-en.png" alt="">
            </div>
            <div>
                <div class="man-tit2">{{ $t('down.zd') }}</div>
                <div style="display: flex;margin-bottom: 24px;" v-show="false">
                    <!-- <img class="apple-img" src="../../assets/market/gg.png" alt="">
                    <img class="apple-img" src="../../assets/market/pg.png" alt=""> -->
                    <div class="box-android">
                      <img class="box-android-img" src="@/assets/img/android.png" alt="">
                      <div class="box-android-text">Android</div>
                    </div>
                    <div class="box-android">
                      <img class="box-android-img" src="@/assets/img/iphone.png" alt="">
                      <div class="box-android-text">IPhone</div>
                    </div>
                </div>
                <div class="box-code">
                    <div style="display: flex;align-items: center;">
                      <!-- <img class="box-code-img" src="../../assets/index/st.png" alt=""> -->
                      <div class="box-code-img" id="qrcode" ref="qrcode"></div>
                      <div >
                        <div class="fs-18">{{ $t('down.xz') }}</div>
                        <div class="fs-14">iOS & Android</div>
                      </div>
                    </div>
                  </div>
            </div>
         </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
  import heads from '../../components/header.vue'
  import footerb from '../../components/footer.vue'
  import QRCode from 'qrcodejs2'
  export default {
    data() {
      return {
        lang:''
      }
    },
    components: {
      footerb,
      heads
    },
    mounted() {
      this.qrcode()
      this.lang = sessionStorage.getItem('lang')
    },
    methods: {
      qrcode () {
        let qrcode = new QRCode("qrcode", {
          width: 82,
          height: 82,
          text: "https://app.kxmbuy.com/"
        });
      },
    }
  };

</script>
<style scoped>
  html {
    background: #171e28;
  }
  .fs-18{
    font-size: 18rpx;
    color: #FFFFFF;margin-bottom: 6px;
  }
  .fs-14{
    font-size: 14rpx;
    color: #FFFFFF;
  }
  .main {
    width: 100%;
    height: 625px;
    opacity: 1;
    background: #1F282F;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 78px 80px;
  }
  .main-tit-flex{
    display: flex;
    align-items: center;
    margin-bottom: 46px;
  }
  .logo {
    width: 80px;
    height: 80px;
    margin-right: 30px;
  }

  .main-tit {
    font-size: 46px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .main-img{
    width: 450px;
    height: 348px;
    margin-right: 70px;
  }
  .apple-img{
    width: 194px;
    height: 60px;
    margin-right: 15px;
  }
  .box-android{
    width: 194px;
    height: 60px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(#27323A, #1C242A,#050607);
    border-radius: 8px;
    margin-right: 15px;
  }
  .box-android-img{
    width: 30px;
    height: 30px;
    margin-left: 25px;
  }
  .box-android-text{
    margin-left: 15px;
    font-size: 20px;
    color: #3ABBF8;
  }


  .box-code {
    width: 350px;
    height:auto;
    background: #171E28;
  }
  .box-code-img{
    margin: 28px;
    background-color: #fff;
    padding: 6px;
  }
  .man-tit2{
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 24px;
  }

</style>
